import { applyMiddleware, createStore, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { appEpics } from './app/app-epics'
import { appReducer } from './app/app-reducer'

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export function initializeStore() {
  const epicMiddleware = createEpicMiddleware()
  const store = createStore(appReducer, composeEnhancers(applyMiddleware(epicMiddleware)))
  epicMiddleware.run(appEpics)
  return store
}
