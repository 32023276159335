import { combineEpics, Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { loadBrandData } from '../sanity-client/sanity-client'
import { readFromLocalStorage, writeToLocalStorage } from '../utils/utils'
import {
  DataActionTypes,
  dataLoadErrorAction,
  dataLoadSuccessAction,
  DATA_LOAD,
  localDataLoadSuccessAction,
} from './data-actions'

// same
// const loadEpic = (action$: Observable<DataActionTypes>): Observable<DataActionTypes>
const loadEpic: Epic<DataActionTypes, DataActionTypes> = action$ =>
  action$.pipe(
    ofType(DATA_LOAD),
    mergeMap(() =>
      from(loadBrandData()).pipe(
        map(data => dataLoadSuccessAction(data)),
        tap(action => {
          writeToLocalStorage(action.data)
        }),
        catchError(() => {
          if (readFromLocalStorage().length > 0) {
            return of(localDataLoadSuccessAction(readFromLocalStorage()))
          } else {
            return of(dataLoadErrorAction())
          }
        }),
      ),
    ),
  )

export const dataEpics = combineEpics(loadEpic)
