import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../app/app-reducer'
import './contacts.scss'
// import { Link } from './link'

export const Contacts = () => {
  const props = useSelector((state: AppState) => state.data.contacts)
  const contacts = props?.contact_options
  // const linkProps = data.find(d => d._type === 'links')

  return props && contacts ? (
    <section className="contacts" id="contacts">
      <div>
        <h3 className="animated" data-animation="shutter-in">
          {props.title}
        </h3>
        <div className="cols">
          <div className="col-1">
            <h5 className="animated" data-animation="shutter-in">
              {props.clients.intro_text}
            </h5>
            <div className="cols-2">
              <div className="col-1 animated" data-animation="shutter-in">
                <p>{props.clients.projects_cta_text}</p>
                <div>
                  <a className="large-a" href={`mailto:${contacts[0].option_title}`}>
                    {contacts[0].option_title}
                  </a>
                  {/* <a className="large-a" href={`tel:${contacts[3].option_title}`}>{contacts[3].option_title}</a> */}
                </div>
              </div>
              {/* <div className="col-2 animated" data-animation="shutter-in">
                <p>{props.clients.form_cta_text}</p>
                <button>{contacts[4].option_title}</button>
              </div> */}
            </div>
          </div>
          {/* <div className="col-2">
            <h5 className="h-medium">{props.candidates.intro_text}</h5>
            <p>{props.candidates.cta_text}</p>
            <a href={`mailto:${contacts[2].option_title}`}>{contacts[2].option_title}</a>
          </div> */}
        </div>
      </div>

      {/* <div className="map-container">
        <div className="map" style={{ backgroundImage: `url(${window.location.origin}/assets/ge-3000.png)` }}>
        </div>
      </div> */}
    </section>
  ) : null
}
