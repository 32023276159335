//@ts-ignore
import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
import { Data, Image } from '../utils/types'

export const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
})

export const imgBuilder = imageUrlBuilder(client)
export const imageUrlFor = (source: Image): string => {
  let src: string | null = imgBuilder.image(source).toString()
  return src === null ? '' : src
}

export const loadBrandData = (): Promise<Data[]> => {
  return client.fetch(
    '*[_type in ["brand","services","clients","contacts","links","career","projects","not_found"]] | order(order)',
  )
}
