import React from 'react'

type Props = {
  title: string
  text: string
  id: string
}

export const CTALink: React.FC<Props> = (props) => {
  const { title , text, id } = props;
  return (
    <div className="outer-link-container cta animated" data-animation="shutter-in">
      <div className="link-container">
        <a href={`#${id}`}>
          {title ? <p className="cta_title">{title}</p> : null}
          {text ? <p className="cta_text">{text}</p> : null}
        </a>
      </div>
    </div>
  )
}