export const MENU_TOGGLE = 'MENU_TOGGLE'

export interface MenuToggleAction {
  type: typeof MENU_TOGGLE
}

export const menuToggle = () => ({
  type: MENU_TOGGLE,
})

export type MenuActionTypes = MenuToggleAction


