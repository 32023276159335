import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { Route, Switch, useLocation } from 'react-router-dom'
import { About } from '../components/about'
import { Canvas } from '../components/canvas'
import { Career } from '../components/career'
import { Clients } from '../components/clients'
import { Contacts } from '../components/contacts'
import { Header } from '../components/header'
import { Hero } from '../components/hero'
import { Menu } from '../components/menu'
import { NotFound } from '../components/not-found'
//import { Project } from '../components/project'
//import { Projects } from '../components/projects'
import { ScrollToTop } from '../components/scroll-to-top'
import { Services } from '../components/services'
import { dataLoadAction } from '../data/data-actions'
import {
  DATA_STATE_LOADING,
  DATA_STATE_LOAD_ERROR,
  DATA_STATE_LOAD_SUCCESS,
  LOCAL_DATA_STATE_LOAD_SUCCESS,
} from '../data/data-reducer'
import { menuToggle } from '../menu/menu-actions'
//import { ROUTES } from '../utils/routes'
import { animate } from '../utils/utils'
import { AppState } from './app-reducer'
import './app.scss'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const loadData = () => dispatch(dataLoadAction())
  const [highContent, setHighContent] = useState(false)
  const dataState = useSelector((state: AppState) => state.data.state)
  const menuState = useSelector((state: AppState) => state.menu.isOpen)
  const pathname = useLocation().pathname
  const match = matchPath(pathname, {
    path: `/`,
    exact: true,
    strict: true,
  })
  //@ts-ignore

  const handleMenuClick = () => {
    dispatch(menuToggle())
  }

  const handleHeightChange = () => {
    let contentHeight = document.body ? Math.round(document.body.getBoundingClientRect().height) : window.innerHeight
    contentHeight > window.innerHeight + 100 ? setHighContent(true) : setHighContent(false)
  }

  useEffect(() => {
    handleHeightChange()
  })

  useEffect(() => {
    dispatch(dataLoadAction())
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('scroll', e => {
      const animatedElements = document.querySelectorAll('.animated')
      animate(animatedElements)
    })
  }, [])

  return (
    <div className="app-container">
      <Canvas />

      {(dataState === DATA_STATE_LOAD_SUCCESS || dataState === LOCAL_DATA_STATE_LOAD_SUCCESS) && (
        <div className="content" id="home">
          <div className={match !== null ? 'top-container' : 'top-container--small'}>
            <Header menu={<Menu />}></Header>
            <Route exact path="/">
              <div
                className={` ${
                  menuState ? 'perspective--modalview effect-rotate-left--animate' : 'perspective effect-rotate-left'
                }`}
              >
                <section className="container section-perspective">
                  <div className={`outer-nav--return ${menuState ? 'is-vis' : ''}`} onClick={handleMenuClick}></div>
                  <Hero />
                </section>
              </div>
            </Route>
          </div>
          <Switch>
            <Route exact path="/">
              <section className="section-perspective">
                <Services />
              </section>
              <section className="section-perspective">
                <About />
              </section>
              <section className="section-perspective">
                <Clients />
              </section>
              {/* <section className="section-perspective">
                <Projects />
              </section> */}
              <section className="section-perspective">
                <Career />
              </section>
              <section className="section-perspective">
                <Contacts />
              </section>
            </Route>
            {/* <Route path={`/${ROUTES.PROJECT}/:id`}>
              <Project />
            </Route> */}
            <Route>
              <div
                className={` ${
                  menuState ? 'perspective--modalview effect-rotate-left--animate' : 'perspective effect-rotate-left'
                }`}
              >
                <section className="container section-perspective">
                  <div className={`outer-nav--return ${menuState ? 'is-vis' : ''}`} onClick={handleMenuClick}></div>
                  <NotFound />
                </section>
              </div>
            </Route>
          </Switch>
          {highContent ? <ScrollToTop /> : null}
        </div>
      )}

      {dataState === DATA_STATE_LOADING && (
        <div className="loader">
          <div>
            <span className="text">Loading</span>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      )}

      {dataState === LOCAL_DATA_STATE_LOAD_SUCCESS && (
        <div className="reload">
          <p>Old content is being loaded</p>
          <div className="link-container">
            <button className="text" onClick={loadData}>
              Retry to Load
            </button>
          </div>
        </div>
      )}

      {dataState === DATA_STATE_LOAD_ERROR && (
        <div className="reload">
          <p className="h-msg">Ohh noo :(</p>
          <div className="link-container">
            <button className="text" onClick={loadData}>
              Retry to Load
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default App
