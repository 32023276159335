import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../app/app-reducer'
// import { Link } from './link'
import './career.scss'

export const Career = () => {
  const career = useSelector((state: AppState) => state.data.career)
  const contacts = useSelector((state: AppState) => state.data.contacts?.contact_options)

  // const linkProps = data.find(d => d._type === 'links')

  return contacts && career ? (
    <div className="career" id="career">
      <h3 className="animated" data-animation="shutter-in">
        {career.title}
      </h3>
      <div className="content">
        <h5 className="animated" data-animation="shutter-in">
          {career.headline}
        </h5>
        <div className="cols">
          <div className="col-1 animated" data-animation="shutter-in">
            <p>{career.text}</p>
            <p>{career.cta_text}</p>
            <div>
              <a className="large-a" href={`mailto:${contacts[2].option_title}`}>
                {contacts[2].option_title}
              </a>
            </div>
          </div>
          <div className="animated col-2" data-animation="shutter-in">
            <h6>{career.positions_intro}</h6>
            <ul className="items">
              {career.positions.map((item, index) => (
                <li key={index}>{item.title}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
