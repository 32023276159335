import { Data } from '../utils/types'
export const DATA_LOAD = 'DATA_LOAD'
export const DATA_LOAD_SUCCESS = 'DATA_LOAD_SUCCESS'
export const DATA_LOAD_ERROR = 'DATA_LOAD_ERROR'
export const LOCAL_DATA_LOAD_SUCCESS = 'LOCAL_DATA_LOAD_SUCCESS'
export const LOCAL_DATA_LOAD_ERROR = 'LOCAL_DATA_LOAD_ERROR'

interface DataLoadActionType {
  type: typeof DATA_LOAD
}

interface DataLoadSuccessActionType {
  type: typeof DATA_LOAD_SUCCESS
  data: Data[]
}

interface DataLoadErrorActionType {
  type: typeof DATA_LOAD_ERROR
}
interface LocalDataLoadSuccessActionType {
  type: typeof LOCAL_DATA_LOAD_SUCCESS
  data: Data[]
}

interface LocalDataLoadErrorActionType {
  type: typeof LOCAL_DATA_LOAD_ERROR
}

export type DataActionTypes = DataLoadActionType | DataLoadSuccessActionType | DataLoadErrorActionType | LocalDataLoadSuccessActionType | LocalDataLoadErrorActionType

export const dataLoadAction = (): DataActionTypes => ({
  type: DATA_LOAD,
})

export const dataLoadSuccessAction = (data: Data[]): DataLoadSuccessActionType => ({
  type: DATA_LOAD_SUCCESS,
  data,
})

export const dataLoadErrorAction = (): DataActionTypes => ({
  type: DATA_LOAD_ERROR,
})

export const localDataLoadSuccessAction = (data: Data[]): DataActionTypes => ({
  type: LOCAL_DATA_LOAD_SUCCESS,
  data,
})

export const localDataLoadErrorAction = (): DataActionTypes => ({
  type: LOCAL_DATA_LOAD_ERROR,
})

