import React from 'react'
import { useSelector } from 'react-redux'
import { CTALink } from './link'
import { AppState } from '../app/app-reducer'
import './services.scss'

export const Services = () => {
  const services = useSelector((state: AppState) => state.data.services)
  const linkProps = useSelector((state: AppState) => state.data.links)

  return services ? (
    <div className="services" id="services">
      <div>
        <h3 className="animated" data-animation="shutter-in">
          {services.title}
        </h3>
      </div>
      <div className="cols">
        <ul className="items">
          {services.services_group.map((group, index) => (
            <div className="item animated" data-animation="shutter-in" key={index}>
              <h4>{group.services_group_title}</h4>
              <ul>{group.services ? group.services.map((service, index) => <li key={index}>{service}</li>) : null}</ul>
              {group.services_description ? <p>{group.services_description}</p> : null}
            </div>
          ))}
          {linkProps ? (
            <div className="item animated" data-animation="shutter-in">
              <CTALink
                text={linkProps.cta[4].link_text}
                title={linkProps.cta[4].link_title}
                id={linkProps.cta[4].link_to_id}
              />
            </div>
          ) : null}
        </ul>
      </div>
    </div>
  ) : null
}
