import React from 'react'
import { useSelector } from 'react-redux'
import { imageUrlFor } from '../sanity-client/sanity-client'
import { AppState } from '../app/app-reducer'
import { CTALink } from './link'
import './clients.scss'

export const Clients = () => {
  const clients = useSelector((state: AppState) => state.data.clients)
  const linkProps = useSelector((state: AppState) => state.data.links)

  return clients ? (
    <div className="clients" id="clients">
      <div className="col-1 animated" data-animation="shutter-in">
        {linkProps ? (
          <CTALink
            text={linkProps.cta[1].link_text}
            title={linkProps.cta[1].link_title}
            id={linkProps.cta[1].link_to_id}
          />
        ) : null}
      </div>
      <div className="animated col-2" data-animation="shutter-in">
        <div className="content">
          <h6 className="h-small-green">{clients.title}</h6>
          <div className="items">
            {clients.clients.map((item, index) => (
              <div key={index}>
                {item.client_logo ? (
                  <a href={item.client_url} target="_blank" rel="noopener noreferrer">
                    <img src={imageUrlFor(item.client_logo)} alt="logo" />
                  </a>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
