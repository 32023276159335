import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../app/app-reducer'
import './not-found.scss'

export const NotFound = () => {
  const notFound = useSelector((state: AppState) => state.data.notFound)
  const linkProps = useSelector((state: AppState) => state.data.links?.menu)

  return notFound ? (
    <div className="not-found">
      <div className="content">
      <div className="col">
        {/* <p className="p--intro">{notFound.text}</p> */}
          <h5>{notFound.title}</h5>
          <h6>{notFound.subtitle}</h6>
          {linkProps ? (
            <div className="outer-link-container">
              <div className="link-container">
                <Link to={`/`}>{linkProps[0].link_text}</Link>
              </div>
            </div>
          ) : null}
        </div>
        <div className="col">
          <div className="radar radar__frame">
          <ul className="radar radar__dial">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          </div>

        </div>

      </div>
    </div>
  ) : null
}
