import {
  DATA_LOAD,
  DATA_LOAD_SUCCESS,
  DATA_LOAD_ERROR,
  LOCAL_DATA_LOAD_SUCCESS,
  LOCAL_DATA_LOAD_ERROR,
  DataActionTypes,
} from './data-actions'
import { DataState, Brand, Services, Career, Clients, Contacts, Links, Project, NotFound } from '../utils/types'

export const DATA_STATE_IDLE = 'DATA_STATE_IDLE'
export const DATA_STATE_LOADING = 'DATA_STATE_LOADING'
export const DATA_STATE_LOAD_SUCCESS = 'DATA_STATE_LOAD_SUCCESS'
export const DATA_STATE_LOAD_ERROR = 'DATA_STATE_LOAD_ERROR'
export const LOCAL_DATA_STATE_LOAD_SUCCESS = 'LOCAL_DATA_STATE_LOAD_SUCCESS'
export const LOCAL_DATA_STATE_LOAD_ERROR = 'LOCAL_DATA_STATE_LOAD_ERROR'

const initialState: DataState = {
  state: DATA_STATE_IDLE,
  data: [],
  brand: undefined,
  services: undefined,
  career: undefined,
  clients: undefined,
  contacts: undefined,
  links: undefined,
  projects: [],
  notFound: undefined,
}

export function dataReducer(state = initialState, action: DataActionTypes): DataState {
  switch (action.type) {
    case DATA_LOAD:
      return {
        ...state,
        state: DATA_STATE_LOADING,
      }
    case DATA_LOAD_SUCCESS:
      return {
        ...state,
        state: DATA_STATE_LOAD_SUCCESS,
        data: action.data,
        brand: action.data.find(d => d._type === 'brand') as Brand,
        services: action.data.find(d => d._type === 'services') as Services,
        career: action.data.find(d => d._type === 'career') as Career,
        clients: action.data.find(d => d._type === 'clients') as Clients,
        contacts: action.data.find(d => d._type === 'contacts') as Contacts,
        links: action.data.find(d => d._type === 'links') as Links,
        projects: action.data.filter(d => d._type === 'projects') as Project[],
        notFound: action.data.find(d => d._type === 'not_found') as NotFound,
      }

    case DATA_LOAD_ERROR:
      return {
        ...state,
        state: DATA_STATE_LOAD_ERROR,
      }
    case LOCAL_DATA_LOAD_SUCCESS:
      return {
        ...state,
        state: LOCAL_DATA_STATE_LOAD_SUCCESS,
        data: action.data,
        brand: action.data.find(d => d._type === 'brand') as Brand,
        services: action.data.find(d => d._type === 'services') as Services,
        career: action.data.find(d => d._type === 'career') as Career,
        clients: action.data.find(d => d._type === 'clients') as Clients,
        contacts: action.data.find(d => d._type === 'contacts') as Contacts,
        links: action.data.find(d => d._type === 'links') as Links,
        projects: action.data.filter(d => d._type === 'projects') as Project[],
        notFound: action.data.find(d => d._type === 'not_found') as NotFound,
      }
    case LOCAL_DATA_LOAD_ERROR:
      return {
        ...state,
        state: LOCAL_DATA_STATE_LOAD_ERROR,
      }
    default:
      return state
  }
}
