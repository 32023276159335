import { Data } from '../utils/types'
export const KEY_DATA : string = 'data'

export const writeToLocalStorage = (data: Data[]) => {
  localStorage.setItem(KEY_DATA, JSON.stringify(data))
}

export const readFromLocalStorage = (): any[] => {
  return JSON.parse(localStorage.getItem(KEY_DATA) || '[]')
}

export const isScrolledIntoView = (el : Element): boolean => {
  let rect = el.getBoundingClientRect()
  let elemTop = rect.top
  let elemBottom = rect.bottom
  let isVisible = elemTop < window.innerHeight && elemBottom >= 0
  return isVisible
}

export const animate = (animatedElements: NodeListOf<Element>) => {
  const screenSize = window.innerWidth
  const mobile = 767
  if (screenSize > mobile) {
    animatedElements.forEach((el: Element) => {
      if (isScrolledIntoView(el)) {
        let animation = el.getAttribute('data-animation') ? el.getAttribute('data-animation') : 'fade-in-slow'
        el.classList.add(animation ? animation : '')
      }
    })
  }
}