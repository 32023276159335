import { combineEpics, Epic } from 'redux-observable'
import { EMPTY } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'
import { dataEpics } from '../data/data-epics'

const logEpic : Epic = (action$, state$) =>
  action$.pipe(
    withLatestFrom(state$),
    mergeMap(() => EMPTY),
)

export const appEpics = combineEpics(logEpic, dataEpics)
