import { combineReducers } from 'redux'
import { dataReducer } from '../data/data-reducer'
import { menuReducer } from '../menu/menu-reducer'

export const appReducer = combineReducers({
  data: dataReducer,
  menu: menuReducer,
})

export type AppState = ReturnType<typeof appReducer>
