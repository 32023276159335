import { MENU_TOGGLE, MenuActionTypes } from './menu-actions'

export interface MenuState {
  isOpen: boolean
}

const initialState: MenuState = {
  isOpen: false,
}

export const menuReducer = (state = initialState, action: MenuActionTypes): MenuState => {
  switch (action.type) {
    case MENU_TOGGLE:
      return {
          ...state,
          isOpen: !state.isOpen,
      }
    default:
      return state
  }
}
