
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { menuToggle } from '../menu/menu-actions'
import { AppState } from '../app/app-reducer'
import { HashLink as Link } from 'react-router-hash-link';
import './menu.scss'

export const Menu = () => {

  const props = useSelector((state: AppState) => state.data.links?.menu)
  const [itemOnHover, setItemOnHover] = useState(-1)
  const dispatch = useDispatch()
  const menuState = useSelector((state: AppState) => state.menu.isOpen)

  const handleMenuHover = (index : number) => {
    setItemOnHover(index)
  }

  const handleMenuClick = () => {
    dispatch(menuToggle())
  }

  return props ? (
    <div className="nav" >
      <nav className="menu">
        <button name="menu" className={`nav-toggle ${menuState ? 'active' : ''}`} onClick={handleMenuClick} onMouseDown={e => e.preventDefault()}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`outer-nav ${menuState ? 'is-vis' : ''}`}>
          {props.map((item, index) => (
            <li className={`${menuState ? 'is-vis' : ''}`} key={index} onClick={handleMenuClick}>
              <div className="link-container">
                <Link to={`/#${item.link_to_id}`} onMouseEnter={() => handleMenuHover(index)} className={`menu-item-${itemOnHover}`}>{item.link_text}</Link>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  ) : null
}
