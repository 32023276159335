import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../app/app-reducer'
import { imageUrlFor } from '../sanity-client/sanity-client'

type Props = {
  menu: JSX.Element
}

export const Header: React.FC<Props> = ({ menu }) => {
  const brandProps = useSelector((state: AppState) => state.data.brand)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 ? true : false)

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    }

    const handleResize = () => {
      if (window.innerWidth <= 768 && isMobile === false) {
        setIsMobile(true)
      }
      if (window.innerWidth > 768 && isMobile === true) {
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile])

  return brandProps ? (
    <header className="header">
      <Link to={`/`} className="header__link">
        <span>
          <object
            type="image/svg+xml"
            title="AMC logo"
            className="logo"
            data={isMobile ? imageUrlFor(brandProps.logo.mark) : imageUrlFor(brandProps.logo.desktop)}
          >
            <img
              src={isMobile ? imageUrlFor(brandProps.logo.mark) : imageUrlFor(brandProps.logo.desktop)}
              alt="AMC logo"
            />
          </object>
        </span>
      </Link>
      {menu}
    </header>
  ) : null
}
