import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../app/app-reducer'
import './about.scss'

export const About = () => {
  const about = useSelector((state: AppState) => state.data.brand?.about)
 
  return about ? (
    <div className="about" id="about">
      <h3 className="animated" data-animation="shutter-in">
        {about.title}
      </h3>
      <div className="cols">
        <div className="col-1 animated" data-animation="shutter-in">
          <div className="story">
            <h5>{about.story.title}</h5>
            <p>{about.story.content}</p>
          </div>
        </div>

        <div className="col-2">
          <div className="h-container animated" data-animation="shutter-in">
            <h5>{about.values.title}</h5>
          </div>

          <div className="items">
            {about.values.value.map((item, index) => (
              <div className="item animated" data-animation="shutter-in" key={index}>
                <h6 className="h-green">{item.title}</h6>
                {item.description ? <p>{item.description}</p> : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
