import React from 'react'
import './scroll-to-top.scss'

export const ScrollToTop = () => {

  return  (
    <div className="arrow-top">
      <button onClick={() => window.scrollTo(0, 0)}>&#x0003C;</button>
    </div>
  )
}
