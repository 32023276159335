import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../app/app-reducer'
import './hero.scss'
import { imageUrlFor } from '../sanity-client/sanity-client'

export const Hero = () => {
  const linkProps = useSelector((state: AppState) => state.data.links)
  const brandProps = useSelector((state: AppState) => state.data.brand)

  return (
    <div className="hero">
      {brandProps ? (
        <div className="content">
          <object
            type="image/svg+xml"
            title="AMC logo mark"
            className="logo-mark"
            data={imageUrlFor(brandProps.logo.mobile)}
          >
            <img src={imageUrlFor(brandProps.logo.mobile)} alt="AMC logo mark" />
          </object>
          <h2 className="">{brandProps.slogan}</h2>
          {linkProps ? (
            <div className="outer-link-container">
              <div className="link-container">
                <a href={`#${linkProps.cta[5].link_to_id}`}>{linkProps.cta[5].link_text}</a>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
